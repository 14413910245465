import React, { Fragment } from 'react';

const profiles = {
    P: {
        label: 'Prudent',
        description: (
            <Fragment>
                <p>Vous êtes un investisseur au profil PRUDENT. Votre premier impératif est la conservation de votre capital
                    et vous n&apos;avez peu de tolérance aux fluctuations des marchés financiers
                    OU vous ne disposez que de relativement peu de temps pour permettre à vos placements de regagner de la valeur à la suite d&apos;une mauvaise année.
                </p>
            </Fragment>
        ),
        data: {
            euro: 0.6,
            uc: 0.4,
        },
    },
    E: {
        label: 'Équilibré',
        description: (
            <Fragment>
                <p>
                    Vous êtes un investisseur au profil EQUILIBRE. Vous êtes disposé à tolérer des fluctuations des marchés boursiers
                    mais votre tolérance au risque est modérée
                    ou vous disposez d&apos;un horizon de placement de moins de 5 ans.
                </p>
            </Fragment>
        ),
        data: {
            euro: 0.3,
            uc: 0.7,
        },
    },
    D: {
        label: 'Dynamique',
        description: (
            <Fragment>
                <p>Vous êtes un investisseur au profil DYNAMIQUE. Vous êtes disposé à tolérer des fluctuations boursières
                    et à accorder un certain temps pour permettre à votre placement de regagner de l&apos;argent en cas de baisse des marchés.
                    Vous êtes un investisseur relativement expériementé à la recherche de rendement.
                </p>
            </Fragment>
        ),
        data: {
            euro: 0,
            uc: 1,
        },
    },
};

export default profiles;
