import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'components/layouts';
import ErrorMessage from './ErrorMessage';

import './PageNotFound.scss';
import withInstanceSettings from './sessions/withInstanceSettings';

const PageNotFound = ({ instanceSettings: { emailSupport } }) => (
    <Layout>
        <ErrorMessage>
            <div className="page-not-found">
                <h1>Oups ! la page demandée n’existe pas…</h1>
                <p>
                    Si le problème persiste vous pouvez contacter par email &agrave;&nbsp;:&nbsp;
                    <b>{emailSupport}</b>
                </p>
                <div className="back-home"><a href="/">Retourner à l&apos;accueil</a></div>
            </div>
        </ErrorMessage>
    </Layout>
);

PageNotFound.propTypes = {
    instanceSettings: PropTypes.shape({
        emailSupport: PropTypes.string,
    }).isRequired,
};

export default withInstanceSettings(PageNotFound);
