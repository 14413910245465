import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'common/components/Icon';
import { connectDisabledForm } from './withDisabledForm';

import './VerticalRadioButton.scss';

const id = ({ name, value }) => `${name}-${value}`;

const VerticalRadioButton = ({ checked, flex, title, disabled, onlyClient, required, ...props }) => (
    <label
        className={classNames('form-radio-button-vertical', { selected: (checked && !disabled) || onlyClient, flex, disabled })}
        htmlFor={id(props)}
    >
        <input id={id(props)} type="radio" {...props} disabled={disabled} required={required} />
        <p>{title}</p>
        {(checked && !disabled) || onlyClient ? (
            <Icon icon="check" />
        ) : null}
    </label>
);

VerticalRadioButton.propTypes = {
    checked: PropTypes.bool,
    onlyClient: PropTypes.bool,
    flex: PropTypes.bool,
    title: PropTypes.node.isRequired,
    disabled: PropTypes.bool.isRequired,
    required: PropTypes.bool.isRequired,
};

VerticalRadioButton.defaultProps = {
    checked: false,
    flex: false,
};

export default connectDisabledForm(VerticalRadioButton);
