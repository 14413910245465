import PropTypes from 'prop-types';
import React from 'react';
import { CreateLink } from 'components/list';
import dataProvider from 'components/dataProvider';
import ContractTable from './ContractTable';

import './List.css';
import TitleLayout from '../layouts/sections/TitleLayout';

const ContractListInner = ({ rip }) => (
    <TitleLayout
        title="SOUSCRIPTION"
        className="title-layout"
    >
        {rip ? (
            <div>
                <ContractTable rip={rip} />
                <CreateLink to={`/rip/${rip.id}/contracts/new`} />
            </div>
        ) : null}
    </TitleLayout>
);

ContractListInner.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
    }),
};

ContractListInner.defaultProps = {
    rip: null,
};

const ContractList = dataProvider({
    id: ({ params: { id } }) => id,
    type: 'rip',
    wait: true,
})(ContractListInner);

export default ContractList;
