import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import { compose, mapProps, branch, renderNothing } from 'recompose';
import React, { Fragment } from 'react';
import { Legend, Pie, PieChart } from 'recharts';
import { formatPercentage } from '../../utils/format';
import getSettings from '../../utils/settings';

const formatLabel = ({ value }) => formatPercentage(value);

const ProductChart = ({ data, colors, withCodes, UCCodesOnly, ...props }) => {
    const baseProducts = getSettings('simulation.lifeInsurance.baseProducts');
    const { paymentType } = props;
    const chartTitle = 'initialPayment' === paymentType ? 'Répartition du versement initial' : 'Répartition du versement programmé';

    return (
        <Fragment>
            <div>
                <h4 className="chartTitle">{chartTitle}</h4>
                <PieChart {...props}>
                    <Pie
                        data={data.map(({ baseProduct, proportion, proportionVersementProgramme }, index) => {
                            const { label } = get(baseProduct, baseProducts);

                            return {
                                name: label,
                                value: 'initialPayment' === paymentType ? (proportion || 0) : (proportionVersementProgramme || 0),
                                fill: colors[index],
                            };
                        })}
                        dataKey="value"
                        nameKey="name"
                        label={formatLabel}
                        isAnimationActive={false}
                        labelLine
                        legendType="circle"
                        outerRadius={80}
                        cx="50%"
                        cy="60%"
                    />
                    <Legend layout="vertical" verticalAlign="left" width={300} />
                </PieChart>
            </div>

        </Fragment>
    );
};

ProductChart.propTypes = {
    paymentType: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    colors: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    withCodes: PropTypes.bool,
    UCCodesOnly: PropTypes.bool,
};

ProductChart.defaultProps = {
    withCodes: false,
    UCCodesOnly: false,
};

export default compose(
    mapProps(({ profile, contractType, ...props }) => ({
        data: getSettings(['simulation', 'lifeInsurance', 'types', contractType, 'profiles', profile, 'repartition']),
        ...props,
    })),
    branch(({ data }) => !data, renderNothing),
)(ProductChart);
