import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import LegalConfidentiality from '../legalDisclaimer/Confidentiality';
import LegalCookies from '../legalDisclaimer/Cookies';
import CGV from '../legalDisclaimer/CGV';
import LegalNotices from '../legalDisclaimer/Notices';
import LegalInformations from '../legalDisclaimer/Informations';
import RipSignatureSuccess from '../signature/SuccessPage/RipSignatureSucces';
import DocumentSignatureSuccess from '../signature/SuccessPage/DocumentSignatureSuccess';
import Login from '../Login';
import PendingValidations from '../PendingValidations';
import Profile from '../profile/Profile';
import Invoices from '../invoices';
import RipAccess from './routers/rip/RipRouter';
import RipList from '../rip/List';
import RipTeamList from '../rip/TeamList';
import AdminMenu from '../admin/Menu';
import InvoicesList from '../admin/invoices/List';
import UserList from '../admin/user/List';
import UserEdit from '../admin/user/Edit';
import UserCreate from '../admin/user/Create';
import EntityList from '../admin/entity/List';
import EntityEdit from '../admin/entity/Edit';
import EntityCreate from '../admin/entity/Create';
import SettingsMenu from '../admin/settings/Menu';
import SettingsEdit from '../admin/settings/Edit';
import SyncLogsList from '../admin/syncLogs/List';
import TermsOfUse from '../termsOfUse';
import Subscription from '../subscription';
import PageNotFound from '../PageNotFound';
import PdfErrorPage from '../PdfErrorPage';
import SignatureRouter from './routers/signature/SignatureRouter';
import PasswordReset from '../passwordReset/PasswordReset';

import Reset from '../Reset';
import PrimonialSsoRedirect from '../PrimonialSsoRedirect';
import { useSession } from '../sessions';

const Routing = () => {
    const session = useSession();
    const location = useLocation();

    const queryParams = new URLSearchParams(window.location.search);
    const redirectUrl = queryParams.get('redirectUrl');

    if (!session.id) {
        return (
            <Routes>
                <Route path="/login" element={<Login location={location} />} />
                <Route path="/reset" element={<PasswordReset />} />
                <Route path="/reset/:id" element={<Reset />} />
                <Route
                    element={<LegalInformations />}
                    path="/legalDisclaimer/informations"
                />
                <Route
                    element={<RipSignatureSuccess />}
                    path="/rip-signature/:ripId"
                />
                <Route
                    element={<DocumentSignatureSuccess />}
                    path="/document-signature/:docId/:ripId"
                />
                <Route path="*" element={<Navigate to="/login" />} />

            </Routes>
        );
    }

    if (!session.termsOfUseAgreement || (!session.isMiddleUser && session.temporaryPassword)) {
        return <TermsOfUse />;
    }

    return (
        <Routes>
            <Route
                key="rip-signature"
                element={<RipSignatureSuccess />}
                path="/rip-signature/:ripId"
            />
            <Route
                key="document-signature"
                element={<DocumentSignatureSuccess />}
                path="/document-signature/:docId/:ripId"
            />
            {!session.signatureOnly
                ? [
                    <Route key="all-rip" path="/" element={<RipList />} />,
                    <Route key="rip-team" path="/team" element={<RipTeamList />} />,
                    <Route key="rip-access" path="/rip/*" element={<RipAccess />} />,
                    <Route key="pendingValidations" path="/pendingValidations" element={<PendingValidations />} />,
                ]
                : null}
            {session.instanceSettings.hasStripeSubscription && [
                <Route key="subscription" path="/subscription" element={<Subscription />} />,
                <Route key="all-invoices" path="/admin/invoices" element={<InvoicesList />} />,
                <Route key="user-invoices" path="/profile/invoices" element={<Invoices />} />,
            ]}
            {session.isAdmin || session.isAdminReseau
                ? [
                    <Route key="admin" path="/admin" element={<AdminMenu />} />,
                    <Route key="all-user" path="/admin/user" element={<UserList />} />,
                    <Route key="new-user" path="/admin/user/create" element={<UserCreate />} />,
                    <Route key="one-user" path="/admin/user/:id" element={<UserEdit />} />,
                    <Route key="all-entity" path="/admin/entity" element={<EntityList />} />,
                    <Route key="new-entity" path="/admin/entity/create/:parentId?" element={<EntityCreate />} />,
                    <Route key="one-entity" path="/admin/entity/:id" element={<EntityEdit />} />,
                    <Route key="settings" path="/admin/settings" element={<SettingsMenu />} />,
                    <Route key="one-settings-group" path="/admin/settings/:group" element={<SettingsEdit />} />,
                    <Route key="sync-logs" path="/admin/syncLogs" element={<SyncLogsList />} />,
                ]
                : null}
            <Route key="profiles" path="/profile" element={<Profile />} />
            <Route key="signatures" path="/signatures" element={<SignatureRouter />} />
            <Route key="pdf" path="/pdfError" element={<PdfErrorPage />} />
            {session.instanceSettings.hasLegalDisclaimer && [
                <Route key="notices" path="/legalDisclaimer/notices" element={<LegalNotices />} />,
                <Route key="confidenciality" path="/legalDisclaimer/confidentiality" element={<LegalConfidentiality />} />,
                <Route key="cookies" path="/legalDisclaimer/cookies" element={<LegalCookies />} />,
                <Route key="cgv" path="/legalDisclaimer/cgv" element={<CGV />} />,
                <Route key="informations" path="/legalDisclaimer/informations" element={<LegalInformations />} />,
            ]}
            <Route key="primonial" path="/sso-redirect-primonial" element={<PrimonialSsoRedirect />} />
            {redirectUrl ? [
                <Route path="*" element={<Navigate to={redirectUrl} />} />,

            ] : [
                <Route path="*" element={<Navigate to="/" />} />,

            ]}
            <Route key="Page-not-found" component={() => <PageNotFound session={session} />} />,

        </Routes>
    );
};

export default Routing;
