/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'common/components/Icon';

const NavButton = ({ list }) => (
    <div className="back-link to-home">
        <Link to={list}>
            <Icon icon="home" />
        </Link>
    </div>
);

NavButton.propTypes = {
    list: PropTypes.string,
};

NavButton.defaultProps = {
    list: null,
};

export default NavButton;
