import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { compose } from 'recompose';
import Icon from 'common/components/fa/Icon';
import { CreateLink } from '../list';
import dataProvider from '../dataProvider';
import SimulationTable from './SimulationTable';
import { withSession } from '../sessions';
import TitleLayout from '../layouts/sections/TitleLayout';

import './List.css';

const SimulationListInner = ({ rip, session }) => (
    <TitleLayout
        title="SIMULATION"
        className="title-layout"
    >
        {rip ? (
            <Fragment>
                <SimulationTable session={session} rip={rip} />
                <CreateLink to={`/rip/${rip.id}/simulations/new`} />
                {session.permissions.mayCreateContract() && (
                    <Link className="list-export-button second" to={`/rip/${rip.id}/contracts`}>
                        <Icon icon="handshake-o" />
                    </Link>
                )}
            </Fragment>
        ) : null}
    </TitleLayout>
);

SimulationListInner.propTypes = {
    rip: PropTypes.shape({
        id: PropTypes.string,
    }),
    session: PropTypes.shape({
        permissions: PropTypes.shape({
            mayCreateContract: PropTypes.func,
        }),
    }),
};

SimulationListInner.defaultProps = {
    rip: null,
};

const SimulationList = compose(
    withSession,
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
        wait: true,
    }),
)(SimulationListInner);

export default SimulationList;
