import PropTypes from 'prop-types';
import React from 'react';
import { formValues } from 'redux-form';
import { compose, withProps } from 'recompose';
import { Layout } from 'components/layouts';
import validate, { checkRequiredField, branchValidate } from 'common/formValidation/lib';
import FormButtons from '../FormButtons';
import { VerticalLayout } from '../form/layout';
import { TextField, SelectField } from '../form/field';
import dataCreationForm from '../dataCreationForm';
import { withSession, useInstanceSettings } from '../sessions';
import withRouter from '../withRouter';
import formatNameAndLastName from '../../utils/formatLastNameAndFirstName';

const Creation = ({ handleSubmit, contactOrigin }) => {
    const { contactOriginTypes } = useInstanceSettings();

    return (
        <Layout className="creation-form">
            <form className="container" onSubmit={handleSubmit} id="main-form">
                <VerticalLayout>
                    <TextField name="firstName" title="Prénom" required format={(value) => formatNameAndLastName(value)} />
                    <TextField name="lastName" title="Nom" required format={(value) => formatNameAndLastName(value, true)} />
                    <SelectField name="contactOrigin" title="Origine du contact" required>
                        <option />
                        {Object.entries(contactOriginTypes).map(([key, value]) => (
                            <option key={key} value={key}>{value}</option>
                        ))}
                    </SelectField>
                    {'other' === contactOrigin && (
                        <TextField name="contactOriginOther" title="Precisez" required />
                    )}
                </VerticalLayout>
                <FormButtons back="/" />
            </form>
        </Layout>
    );
};

Creation.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    contactOrigin: PropTypes.string,
};

const formValidation = validate([
    checkRequiredField('firstName'),
    checkRequiredField('lastName'),
    checkRequiredField('contactOrigin'),
    branchValidate((errors, { contactOrigin }) => 'other' === contactOrigin, [
        checkRequiredField('contactOriginOther'),
    ]),
]);

export default compose(
    withRouter,
    withSession,
    withProps(({ session }) => ({
        initialValues: {
            attachedPDF: true,
            userId: session.id,
            birthCountry: 'FR',
            partnerBirthCountry: 'FR',
            taxResidency: 'FR',
            partnerTaxResidency: 'FR',
            nationality: 'FR',
            partnerNationality: 'FR',
            financialCapacity: true,
            partner: {
                hasFinancialCapacity: true,
            },
        },
    })),
    dataCreationForm({
        form: 'rip-creation',
        onSubmitSuccess: (result, _, { navigate }) => navigate(`/rip/${result.id}/etat-civil`),
        validate: formValidation,
        type: 'rip',
    }),
    formValues('contactOrigin'),
)(Creation);
