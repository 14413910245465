import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { ResponsiveContainer } from 'recharts';
import colors from 'components/colors';
import Chart from 'common/components/simulation/ProductChart';

import './ProductChart.scss';

const ProductChart = ({ className, ...props }) => (
    <div className={classNames('simulation-av-product-chart', className)}>
        <ResponsiveContainer height={350}>
            <Chart
                {...props}
                colors={colors}
                cx="75%"
                cy="50%"
            />
        </ResponsiveContainer>
    </div>
);

ProductChart.propTypes = {
    className: PropTypes.string,
    profile: PropTypes.string,
    contractType: PropTypes.string,
};

ProductChart.defaultProps = {
    className: null,
};

export default ProductChart;
