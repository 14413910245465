const formatNameAndLastName = (value, isLastName = false) => {
    if (!value) { return ''; }

    return value
        .split(/(\s|-)/) // Sépare mais conserve les espaces et tirets
        .map((part) => {
            if (part.match(/\s|-/)) {
                return part;
            }
            if (isLastName) {
                return part.toUpperCase(); // Tout en majuscules pour un nom de famille
            }

            return part.charAt(0).toUpperCase() + part.slice(1).toLowerCase(); // Majuscule uniquement sur la première lettre
        })
        .join(''); // Réassemble tout en gardant les séparateurs
};

export default formatNameAndLastName;
