/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setModal } from 'actions';
import withRouter from './withRouter';

const SuccessModalForPreco = ({ close }) => (
    <div className="modal-container">
        <p>Votre message a bien &eacute;t&eacute; envoy&eacute; !</p>
        <a onClick={close}>
            Retour vers la liste des RIPs
        </a>
    </div>
);

SuccessModalForPreco.propTypes = {
    close: PropTypes.func.isRequired,
};

export default compose(
    withRouter,
    connect(null, (dispatch, {
        navigate,
    }) => ({
        close: () => {
            dispatch(setModal(null));
            navigate('/');
        },
    })),
)(SuccessModalForPreco);
