import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';
import React, { Fragment, useEffect, useState } from 'react';
import _ from 'lodash';
import {
    CreateLink,
    ExportButton,
    ExportCGPClientsButton,
    InfiniteScroll,
    ExportButtonArchived,
    ExportButtonOutDated,
} from 'components/list';
import ListTable, { itemShape } from 'components/rip/ListTable';
import listProvider from './listProvider';
import isWhiteLabelSga from '../../../utils/isWhiteLabelSga';

const Body = ({ items, isLoading, mode, listeRips, motRecherche, nombreTotalDeRips, tri, setTri, recuperationDonnees, session, setRipAEnlever }) => {
    const userId = session.id;
    const userStatus = session.status;
    const isEntityQuintesens = isWhiteLabelSga('quintesens');

    const [tousLesRips, setTousLesRips] = useState([]);
    const [ordonne, setOrdonne] = useState({ property: 'updatedAt', order: 'desc' });
    const [size, setSize] = useState(10);

    const pageSize = 20;

    useEffect(() => {
        if (!_.isEmpty(ordonne)) {
            setTri(ordonne);
            setSize(10);
        }
    }, [ordonne]);

    useEffect(() => {
        setTousLesRips(listeRips);
    }, [listeRips]);

    const chargerPlusDeDonnees = async () => {
        const page = Math.ceil(tousLesRips.length / pageSize);

        if ('user' === mode) {
            return setTimeout(() => setSize(page * pageSize), 1);
        }

        return setTimeout(async () => {
            let ripsRecuperes = await recuperationDonnees(mode, pageSize, tousLesRips.length, tri, motRecherche);
            ripsRecuperes = await ripsRecuperes.json();
            setTousLesRips([...tousLesRips, ...ripsRecuperes.rows]);

            return setSize(page * pageSize);
        }, 1);
    };

    const encoreDesDonneesACharger = () => {
        if ('user' === mode) {
            return !isEmpty(items) && size < items.length;
        }

        return size < nombreTotalDeRips;
    };

    const estDansModeDifferentDeUser = (modeSelectionne) => 'all' === modeSelectionne || 'allForManager' === modeSelectionne || 'archives' === modeSelectionne || 'outdated' === modeSelectionne;

    return (
        <Fragment>
            <InfiniteScroll
                component={ListTable}
                items={estDansModeDifferentDeUser(mode) ? tousLesRips : items}
                isLoading={isLoading}
                mode={mode}
                setRipAEnlever={setRipAEnlever}
                miseAJourListe={setOrdonne}
                loadMore={chargerPlusDeDonnees}
                hasMore={encoreDesDonneesACharger()}
                dataLength={estDansModeDifferentDeUser(mode) ? tousLesRips.length : items.length}
            />
            {'user' === mode && (
                <Fragment>
                    <ExportCGPClientsButton items={items} userId={userId} userStatus={userStatus} />
                    {!isEntityQuintesens && (
                        <CreateLink to="/rip/new" />
                    )}
                </Fragment>
            )}
            {'all' === mode && <ExportButton items={items} userStatus={userStatus} />}
            {'archives' === mode && <ExportButtonArchived items={items} userId={userId} userStatus={userStatus} />}
            {'outdated' === mode && <ExportButtonOutDated items={items} userId={userId} userStatus={userStatus} />}
        </Fragment>
    );
};

Body.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
    isLoading: PropTypes.bool.isRequired,
    mode: PropTypes.string,
    listeRips: PropTypes.arrayOf(PropTypes.shape({})),
    motRecherche: PropTypes.string,
    nombreTotalDeRips: PropTypes.number,
    tri: PropTypes.shape({}),
    setTri: PropTypes.func,
    recuperationDonnees: PropTypes.func,
    session: PropTypes.shape({
        id: PropTypes.string,
        status: PropTypes.string,
    }),
    setRipAEnlever: PropTypes.func,
};

Body.defaultProps = {
    mode: 'user',
};

export default listProvider(Body);
