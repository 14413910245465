import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { setModal } from 'actions';
import withRouter from './withRouter';

import './SuccessModal.scss';

const SuccessModal = ({ close }) => (
    <div className="modal-container">
        <p>Votre message a bien été envoyé !</p>
        <button
            onClick={close}
            className="lastButtonRipForm sendButton"
            type="button"
        >
            Retour vers la liste des rips
        </button>
    </div>
);

SuccessModal.propTypes = {
    close: PropTypes.func.isRequired,
    session: PropTypes.shape({
        initialValues: PropTypes.shape({
            user: PropTypes.shape({
                entity: PropTypes.shape({
                    name: PropTypes.string,
                }),
            }),
        }),
    }),
};

export default compose(
    withRouter,
    connect(null, (dispatch, { navigate }) => ({
        close: () => {
            dispatch(setModal(null));
            navigate('/');
        },
    })),
)(SuccessModal);
