import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { compose, pure } from 'recompose';

import { Layout } from 'components/layouts';
import { listProvider, HideActiveItemButton, withActiveItem } from '../../list';
import { withSession } from '../../sessions';
import { userShape } from './Item';
import { GET } from '../../../utils/httpMethods';
import teamListTable from './TeamListTable';
import './index.css';
import ModeForm from './ModeForm';

const TeamListInner = ({ itemsDesactive, itemsActive }) => {
    const [isInactiveWithRips, setIsInactiveWithRips] = useState([]);
    const [isInactiveWithoutRipsActive, setIsInactiveWithoutRipsActive] = useState([]);
    const [itemsActiveRips, setitemsActiveRips] = useState([]);
    const [mode, setMode] = useState('isInactiveWithRips');

    useEffect(() => {
        (async () => {
            const handleUsers = async (ite) => {
                const results = await Promise.all(ite.map(async (user) => {
                    try {
                        const donnees = await GET(`/api/rips/user-rips/${user.id}/updatedAt/DESC`);
                        const ripsRecuperes = await donnees.json();

                        if (ripsRecuperes.count > 0) {
                            return {
                                user,
                                rips: ripsRecuperes.rows, // Stocker les RIPs pour filtrage
                            };
                        }

                        return null;
                    } catch (error) {
                        return null;
                    }
                }));

                return results.filter(result => result !== null);
            };

            handleUsers(itemsDesactive)
                .then(usersWithRips => {
                    setitemsActiveRips(itemsActive);

                    if (usersWithRips.length > 0) {
                        // Séparer les utilisateurs :
                        const usersOnlyInactiveRips = [];
                        const usersWithActiveRips = [];

                        usersWithRips.forEach(({ user, rips }) => {
                        // Filtrer les RIPs actifs (retirer les archivés et supprimés)
                            const activeRips = rips.filter(rip => !rip.archived && !rip.deleted);

                            if (0 === activeRips.length) {
                                usersOnlyInactiveRips.push(user); // Tous les RIPs sont archivés ou supprimés
                            } else {
                                usersWithActiveRips.push({ ...user, rips: activeRips }); // Garder que les actifs
                            }
                        });

                        setIsInactiveWithRips(usersWithActiveRips);
                        setIsInactiveWithoutRipsActive(usersOnlyInactiveRips);
                    }
                });
        })();
    }, [itemsDesactive]);

    return (
        <Layout className="team-list">
            <div className="container">

                <ModeForm setMode={setMode} mode={mode} />

                {'isInactiveWithRips' === mode && teamListTable({
                    items: isInactiveWithRips,
                    type: 'isInactiveWithRips',
                })}

                {'itemsActiveRips' === mode && teamListTable({
                    items:
                    itemsActiveRips,
                    type: 'activeItems',
                })}

                {'isInactiveWithoutRipsActive' === mode && teamListTable({
                    items: isInactiveWithoutRipsActive,
                    type: 'isInactiveWithoutRipsActive',
                })}

                <HideActiveItemButton />
            </div>

        </Layout>
    );
};
TeamListInner.propTypes = {
    itemsDesactive: PropTypes.arrayOf(PropTypes.shape(userShape).isRequired).isRequired,
    itemsActive: PropTypes.arrayOf(PropTypes.shape(userShape).isRequired).isRequired,
};

const TeamList = compose(
    withSession,
    listProvider({
        sorts: {
            lastName: { initialOrder: 'asc', default: true },
        },
        filter: ({ session }) => ({ entityId }) => session.managedIds.includes(entityId) || session.permissions.isAdmin(),
        name: 'user',
        type: 'user',
    }),
    withActiveItem,
    pure,
)(TeamListInner);

export default TeamList;
