import { isCouple } from 'common/utils/hasPartner';

export const hasSignedElectronicallyClient = (rip) => (
    !!rip.clientAndPartnerSignatureDate && rip.clientAndPartnerSignatureDate.client && 'électroniquement' === rip.clientAndPartnerSignatureDate.client.type && 'completed' === rip.universignTransactionState
);

export const hasSignedElectronicallyPartner = (rip) => (
    !!rip.clientAndPartnerSignatureDate && rip.clientAndPartnerSignatureDate.partner && 'électroniquement' === rip.clientAndPartnerSignatureDate.partner.type && 'completed' === rip.universignTransactionState
);

export const hasSignedManuallyClient = (rip) =>
    (!!rip.signature);

export const hasSignedManuallyPartner = (rip) => (
    !!rip.partnerSignature
);

export const hasSignedClient = (rip) => (
    !!hasSignedElectronicallyClient(rip) || hasSignedManuallyClient(rip)
);

export const hasSignedPartner = (rip) => (
    !!hasSignedElectronicallyPartner(rip) || hasSignedManuallyPartner(rip)

);

export const hasSignedClientAndPartner = (rip) => (
    !!hasSignedClient(rip) && hasSignedPartner(rip)
);

export const separationRegime = (rip) => (
    'separationAsToProperty' === rip.communityRegime || 'separationOfEstate' === rip.communityRegime
);

export const hasSignedElectronically = (rip) => !!rip.universignTransaction && 'completed' === rip.universignTransactionState;

export const hasSignedManually = (rip) => (
    !!(rip.signature && (
        !isCouple(rip.familySituation) || rip.partnerSignature
    ))
);

export const hasOngoingSignatureProcess = (rip) => (
    (!!rip.universignTransaction && ['completed', 'ready', 'canceled'].includes(rip.universignTransactionState))
);

const hasSigned = (rip, session = {}) => (
    (hasSignedElectronically(rip, session) || hasSignedManually(rip, session))
);

export default hasSigned;
