import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import React, { useCallback, useState, useEffect } from 'react';
import FlagProvider from '@unleash/proxy-client-react';
import { createMemoryRouter, BrowserRouter } from 'react-router-dom';

import { SessionProvider } from './components/sessions';
import Bootstrap from './components/Bootstrap';
import { Provider as AppDataProvider } from './components/withAppData';
import ErrorBoundary from './components/ErrorBoundary';
import { withOnlineProvider } from './components/withOnline';
import getConfigUnleash from './utils/getConfigUnleash';

import './App.css';
import './animations.scss';
import ErrorPage from './components/ErrorBoundary/ErrorPage';

const App = ({ appData }) => {
    const [confUnleash, setConfigUnleash] = useState(null);
    const [readyForFlag, setReadyForFlag] = useState(false);

    useEffect(() => {
        (async () => {
            const data = await getConfigUnleash();
            if (data) {
                setConfigUnleash(data);
                setReadyForFlag(true);
            }
        })();
    }, []);

    const routes = [
        {
            path: '/errorPage',
            element: <ErrorPage />,
        },
    ];
    const navigateRouter = createMemoryRouter(routes, {
        initialEntries: ['/'],
        initialIndex: 1,
    });

    // the real render method memoize on history only
    const renderBootstrap = useCallback((session, key) => (
        <Bootstrap
            navigateRouter={navigateRouter}
            session={session}
            key={key}
        />
    ), [navigateRouter]);

    const tagManagerArgs = {
        gtmId: 'GTM-WWZQZ5D',
    };

    TagManager.initialize(tagManagerArgs);

    return (
        <AppDataProvider value={appData}>
            <BrowserRouter navigate={navigateRouter}>
                <ErrorBoundary>
                    {readyForFlag &&
                        <FlagProvider config={confUnleash} >
                            <SessionProvider>
                                {renderBootstrap}
                            </SessionProvider>
                        </FlagProvider>
                    }
                </ErrorBoundary>
            </BrowserRouter>
        </AppDataProvider>
    );
};

App.propTypes = {
    appData: PropTypes.shape({}),
};

App.defaultProps = {
    appData: null,
};

export default withOnlineProvider(App);
