const choices = {
    active: 'Actif',
    seekingJob: 'Demandeur d\'emploi',
    student: 'Étudiant/Apprenti/Élève',
    retired: 'Retraité',
    unemployed: 'Sans activité salariale',
    TNS: 'TNS',
};

export default choices;
