import { mapValues } from 'lodash/fp';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm, formValues } from 'redux-form';
import { connect } from 'react-redux';
import GridRadioChoices from 'components/form/GridRadioChoices';
import modesData from './modes';
import { withSession } from '../sessions';

const isCaseManager = (session) => session.permissions.isCaseManager();

const getFilters = (mode, session) => {
    if (isCaseManager(session) || (session.needApprovalOnSimulations && session.needApprovalOnCIFDocument)) {
        return ({
            user: modesData.modesCifAndSimulations[mode].user.subLabel,
            all: modesData.modesCifAndSimulations[mode].all.subLabel,
        });
    }
    if (!session.needApprovalOnSimulations && session.needApprovalOnCIFDocument) {
        return ({
            user: modesData.modesCifOnly[mode].user.subLabel,
            all: modesData.modesCifOnly[mode].all.subLabel,
        });
    }

    return ({
        user: modesData.modesSimulationOnly[mode].user.subLabel,
        all: modesData.modesSimulationOnly[mode].all.subLabel,
    });
};

const getFiltersOnlyUser = (mode, session) => {
    if (session.needApprovalOnSimulations && session.needApprovalOnCIFDocument) {
        return ({
            user: modesData.modesCifAndSimulations[mode].user.subLabel,
        });
    }
    if (!session.needApprovalOnSimulations && session.needApprovalOnCIFDocument) {
        return ({
            user: modesData.modesCifOnly[mode].user.subLabel,
        });
    }

    return ({
        user: modesData.modesSimulationOnly[mode].user.subLabel,
    });
};

const mapStateToProps = (state) => ({
    activated: state.activated,
});

const ModeForm = ({ mode, session }) => {
    let modes;

    let type = mode;

    if ((session.needApprovalOnSimulations && session.needApprovalOnCIFDocument) || isCaseManager(session)) {
        modes = mapValues(({ label }) => label, modesData.modesCifAndSimulations);
    } else if (!session.needApprovalOnSimulations && session.needApprovalOnCIFDocument) {
        modes = mapValues(({ label }) => label, modesData.modesCifOnly);
        type = 'documentsCIF';
    } else {
        modes = mapValues(({ label }) => label, modesData.modesSimulationOnly);
    }

    return (
        <div className="container rip-mode-form">
            {
                (isCaseManager(session) || (session.needApprovalOnSimulations && session.needApprovalOnCIFDocument)) ?
                    <GridRadioChoices
                        choices={modes}
                        name="mode"
                        className="dynamic"
                    /> : null
            }
            {
                isCaseManager(session) ? <GridRadioChoices
                    choices={getFilters(type, session)}
                    name="filter"
                    className="dynamic"
                /> : <GridRadioChoices
                    choices={getFiltersOnlyUser(type, session)}
                    name="filter"
                    className="dynamic"
                />
            }
        </div >
    );
};

ModeForm.propTypes = {
    mode: PropTypes.string.isRequired,
    session: PropTypes.shape({
        needApprovalOnSimulations: PropTypes.bool,
        needApprovalOnCIFDocument: PropTypes.bool,
        status: PropTypes.string,
    }).isRequired,
};

export default compose(
    withSession,
    connect(mapStateToProps),
    reduxForm({
        initialValues: { mode: 'simulations', filter: 'user' },
        form: 'pendingValidation-modes',
    }),
    formValues({ mode: 'mode' }),
)(ModeForm);
