import PropTypes from 'prop-types';
import React from 'react';
import withRouter from '../withRouter';
import RipAppointmentForm from './appointment/Form';
import RipLayout from './RipLayout';

const Appointment = ({ params: { id }, history }) => (
    <RipLayout>
        <RipAppointmentForm
            back={`/rip/${id}/commitment`}
            nextButtonContent="Simuler"
            id={id}
            last
            history={history}
        />
    </RipLayout>
);

Appointment.propTypes = {
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    history: PropTypes.shape({}).isRequired,
};

export default withRouter(Appointment);
