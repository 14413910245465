// Investissements financiers

import { sum } from 'lodash/fp';

const dispInvestmentTypes = [
    'currentAccount',
    'LDD',
    'savingsAccount',
    'PEL',
    'CEL',
    'CSL',
];

const computeInvestments = (rip) => {
    if (!rip.investments || !rip.investments.length) {
        return null;
    }

    const investissementAvecCapital = rip.investments.filter((x) => x.capital);
    const investissementAvecValeur = rip.investments.filter((x) => x.value);
    const versementsMensuels = rip.investments.filter((x) => x.monthlyPayment);
    const disponibilites = sum(rip.investments.filter((x) => dispInvestmentTypes.includes(x.type)).map((investissement) => investissement.capital));

    const investissementsTotal =
        sum(investissementAvecCapital.map((investissement) => investissement.capital)) +
        sum(investissementAvecValeur.map((investissement) => investissement.value));

    const totalDesVersementsMensuels = sum(versementsMensuels.map((x) => x.monthlyPayment));

    const financialInvestments = { investissementsTotal, totalDesVersementsMensuels };

    return { financialInvestments, disponibilites };
};

export default computeInvestments;
