import { Layout } from 'components/layouts';
import { flowRight, get, isFunction } from 'lodash/fp';
import PropTypes from 'prop-types';
import React, { createElement, useEffect, useState } from 'react';
import { withProps, withState } from 'recompose';
import { formValues } from 'redux-form';
import { connect } from 'react-redux';

import { GET } from '../../utils/httpMethods';
import { withDatabase } from '../database';
import dataCreationForm from '../dataCreationForm';
import dataProvider from '../dataProvider';
import { InputField, SelectField } from '../form/field';
import { VerticalLayout } from '../form/layout';
import FormButtons from '../FormButtons';
import { withSession } from '../sessions';
import formValidate from './formValidate';
import getUrl from './getUrl';
import types from './types';

import './Creation.scss';
import withRouter from '../withRouter';

const WithProduct = formValues('product')(({ product, children }) => children(product));

const CreationInner = ({
    handleSubmit,
    params: { id },
    rip,
    session,
}) => {
    const [hasValidOriasNumber, setHasValidOriasNumber] = useState(null);

    const check = session.permissions.mayCreateSimulation.bind(session.permissions, rip);
    const { instanceTheseis } = session;

    const runCreationChecks = 'cohabitation' === rip.familySituation && rip.dualRip
        ? (product) => check(product) || check(product, 'partner')
        : check;

    useEffect(() => {
        (async () => {
            let validOriasNumberResponse = await GET(`/api/users/${session.id}/validOriasNumber`);
            validOriasNumberResponse = await validOriasNumberResponse.json();
            setHasValidOriasNumber(validOriasNumberResponse);
        })();
    }, []);

    if (null !== hasValidOriasNumber) {
        if (!hasValidOriasNumber || !instanceTheseis) {
            delete types.per;
        }
    }

    return (
        <Layout className="simulation-form creation-form" hasFooter>
            <div>
                <form onSubmit={handleSubmit} id="main-form">
                    <div>
                        <div className="simulation-form-inner">
                            <div className="container">
                                <VerticalLayout>
                                    <InputField name="name" title="Nom de la simulation" type="text" required />
                                    <SelectField name="product" title="Produit" required>
                                        <option />
                                        {Object
                                            .entries(types)
                                            .map(([productKey, { label }]) => (
                                                runCreationChecks(productKey)
                                                    ? <option value={productKey} key={productKey}>{label}</option>
                                                    : null))}
                                    </SelectField>
                                    <WithProduct>
                                        {(product) => {
                                            // get the additional fields component
                                            const AdditionalFieldComponent = get([product, 'formComponent'], types);

                                            return AdditionalFieldComponent
                                                ? createElement(AdditionalFieldComponent, { rip })
                                                : null;
                                        }}
                                    </WithProduct>
                                </VerticalLayout>
                            </div>
                        </div>
                        <FormButtons
                            back={`/rip/${id}/simulations/`}
                            list={`/rip/${id}/simulations/`}
                            isHiddenButton={!navigator.onLine}
                        />
                    </div>
                </form>
            </div>

        </Layout>
    );
};

CreationInner.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        id: PropTypes.string.isRequired,
    }).isRequired,
    rip: PropTypes.shape(),
    session: PropTypes.shape({
        instanceIdsoft: PropTypes.string,
        instanceInovia: PropTypes.string,
        instanceTheseis: PropTypes.bool,
        id: PropTypes.string,
        oriasNumber: PropTypes.string,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
        instanceSettings: PropTypes.shape({
            label: PropTypes.string,
        }),
        permissions: PropTypes.shape({
            isCaseManager: PropTypes.func,
            mayCreateSimulation: PropTypes.shape({
                bind: PropTypes.func,
            }),
        }),
    }).isRequired,
};

const Creation = flowRight([
    withRouter,
    withProps(({ params: { id } }) => ({
        initialValues: {
            ripId: id,
            beneficiary: 'client',
        },
    })),
    dataProvider({
        id: ({ params: { id } }) => id,
        type: 'rip',
        wait: true,
    }),
    withDatabase,
    withSession,
    withState('productName', 'setProductName', null),
    connect((state, props) => {
        const { setProductName } = props;
        setProductName(get('form.simulation-creation.values.name', state));
    }),
    withProps(({ productName }) => ({
        productName,
    })),
    dataCreationForm({
        form: 'simulation-creation',
        onSubmitSuccess: (result, dispatch, { navigate }) => {
            navigate(getUrl(result));
        },
        type: 'simulation',
        // eslint-disable-next-line consistent-return
        onSubmitData: (values, props) => {
            const productValues = get([values.product, 'initialValues'], types);

            return ({
                ...(isFunction(productValues) ? productValues(values, props) : productValues),
                ...values,
            });
        },
        validate: formValidate,
    }),
])(CreationInner);

export default Creation;
