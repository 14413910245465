import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Icon from 'common/components/Icon';

const Button = (props) => <button {...props}>{props.children}</button>;

Button.propTypes = {
    children: PropTypes.node.isRequired,
};

const NextButton = ({ formId, nextButtonContent, disabled, nextRedirectTo }) => (
    <div className="formNextAction">
        {nextRedirectTo ? (
            <Link to={nextRedirectTo}>
                <button >
                    {nextButtonContent}&nbsp;
                    <Icon icon="angle-right" />
                </button>
            </Link>
        ) : (
            <button type="submit" form={formId} disabled={disabled}>
                {nextButtonContent}&nbsp;
                <Icon icon="angle-right" />
            </button>
        )}
    </div>
);

NextButton.propTypes = {
    nextButtonContent: PropTypes.string,
    formId: PropTypes.string,
    disabled: PropTypes.bool,
    nextRedirectTo: PropTypes.string,
    usersIds: PropTypes.shape({}),
    session: PropTypes.shape({}),
};

NextButton.defaultProps = {
    nextButtonContent: 'Continuer',
    formId: 'main-form',
    disabled: false,
    nextRedirectTo: null,
};

export default NextButton;
