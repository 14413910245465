import React, { useEffect, useState } from 'react';
import { get } from 'lodash/fp';
import _ from 'lodash';
import ReactMarkdown from 'react-markdown';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox } from 'react-bootstrap';
import { RipLayout } from 'components/layouts';
import Icon from 'common/components/fa/Icon';
import Body from './Body';
import ModeForm from './ModeForm';
import BrowserWarning from './BrowserWarning';
import VersionWarning from './VersionWarning';

import './List.scss';
import { GET, PATCH } from '../../../utils/httpMethods';
import { withSession } from '../../sessions';

const List = ({ session, valeurRecherche }) => {
    const [modalOuverte, setModalOuverte] = useState(false);
    const [lastCall, setLastCall] = useState('');
    const [contenuModal, setContenuModal] = useState({});
    const [enLigne, setEnLigne] = useState(true);
    const [motRecherche, setMotRecherche] = useState('');
    const [mode, setMode] = useState('');
    const [tri, setTri] = useState({ property: 'updatedAt', order: 'desc' });
    const [ripsAAfficher, setRipsAAfficher] = useState([]);
    const [ripsAAfficherAll, setRipsAAfficherAll] = useState([]);
    const [ripsAAfficherAllForManager, setRipsAAfficherAllForManager] = useState([]);
    const [ripsAAfficherOutdated, setRipsAAfficherOutdated] = useState([]);
    const [ripsAAfficherArchives, setRipsAAfficherArchives] = useState([]);

    const [nombreTotalDeRips, setNombreTotalDeRips] = useState(0);

    const [ripAEnlever, setRipAEnlever] = useState(null);

    useEffect(() => {
        if (false === navigator.onLine) {
            return setEnLigne(false);
        }

        return setEnLigne(true);
    }, [navigator.onLine]);

    useEffect(() => {
        GET('/api/settings')
            .then(async (settings) => {
                const settingsRecuperees = await settings.json();
                let utilisateursRecuperes = await GET(`/api/users/${session.id}/personalDetails`);
                utilisateursRecuperes = await utilisateursRecuperes.json();

                const dateDuJour = new Date();

                if (_.get(settingsRecuperees, 'global.communication.dateDebutPublication') && _.get(settingsRecuperees, 'global.communication.dateFinPublication')) {
                    const dateDebutPublication = new Date(settingsRecuperees.global.communication.dateDebutPublication);
                    const dateFinPublication = new Date(settingsRecuperees.global.communication.dateFinPublication);
                    setContenuModal(settingsRecuperees.global.communication);

                    if (dateDebutPublication <= dateDuJour && dateFinPublication > dateDuJour) {
                        if (false === utilisateursRecuperes.hasReadPopUp) {
                            setModalOuverte(true);
                        }
                    }
                }
            });
    }, []);

    const metAjourLecturePopUp = async () => {
        await PATCH('/api/users/aLuPopUp');
    };

    const sortOrder = (triSelectionne) => (triSelectionne.order ? triSelectionne.order.toUpperCase() : 'DESC');
    const itemsPerPage = (nombreParPage, nombreAIgnorer) => `${nombreParPage}${nombreAIgnorer ? `/${nombreAIgnorer}` : ''}`;

    const definieRouteSelonSiRechercheEnModeUser = (triSelectionne) => GET(`/api/rips/user-rips/${session.id}/${triSelectionne.property}/${sortOrder(triSelectionne)}`);

    const definieRouteSelonSiRechercheEnModeAll = (nombreParPage, nombreAIgnorer, triSelectionne, recherche) => {
        if (recherche && 0 < recherche.length) {
            return GET(`/api/rips/recherche/${recherche}/${triSelectionne.property}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
        }

        return GET(`/api/rips/tries/${triSelectionne.property}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
    };

    const definieRouteSelonSiRechercheEnModeAllForManager = (nombreParPage, nombreAIgnorer, triSelectionne, recherche) => {
        if (recherche && 0 < recherche.length) {
            if ('cdd' === session.status && session.managerCddIds) {
                return GET(`/api/rips/recherche/cdd-entity-manager/${session.entity.id}/${session.id}/${recherche}/${triSelectionne.property}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
            }

            return GET(`/api/rips/recherche/entity-manager/${session.entity.id}/${recherche}/${triSelectionne.property}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
        }
        if ('cdd' === session.status && session.managerCddIds) {
            return GET(`/api/rips/entity-manager/${session.entity.id}/${session.id}/${triSelectionne.property}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
        }

        return GET(`/api/rips/entity-manager/${session.entity.id}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
    };

    const definieRouteSelonSiRechercheEnModeArchives = (nombreParPage, nombreAIgnorer, triSelectionne, recherche) => {
        if (recherche && 0 < recherche.length) {
            return GET(`/api/rips/all-archives/recherche/${recherche}/${triSelectionne.property}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
        }

        return GET(`/api/rips/all-archives-tries/${triSelectionne.property}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
    };

    const definieRouteSelonSiRechercheEnModeOutdated = (nombreParPage, nombreAIgnorer, triSelectionne, recherche) => {
        if (recherche && 0 < recherche.length) {
            return GET(`/api/rips/all-outdated/recherche/${recherche}/${triSelectionne.property}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
        }

        return GET(`/api/rips/all-outdated-tries/${triSelectionne.property}/${sortOrder(triSelectionne)}/${itemsPerPage(nombreParPage, nombreAIgnorer)}`);
    };

    const recupereLesDonnees = (modeSelectionne, nombreParPage, nombreAIgnorer, triSelectionne, recherche) => {
        if ('user' === modeSelectionne) {
            return definieRouteSelonSiRechercheEnModeUser(triSelectionne);
        }

        if ('all' === modeSelectionne) {
            return definieRouteSelonSiRechercheEnModeAll(nombreParPage, nombreAIgnorer, triSelectionne, recherche);
        }

        if ('allForManager' === modeSelectionne) {
            return definieRouteSelonSiRechercheEnModeAllForManager(nombreParPage, nombreAIgnorer, triSelectionne, recherche);
        }

        if ('archives' === modeSelectionne) {
            return definieRouteSelonSiRechercheEnModeArchives(nombreParPage, nombreAIgnorer, triSelectionne, recherche);
        }

        if ('outdated' === modeSelectionne) {
            return definieRouteSelonSiRechercheEnModeOutdated(nombreParPage, nombreAIgnorer, triSelectionne, recherche);
        }

        return '';
    };

    useEffect(() => {
        if (lastCall !== `${valeurRecherche}-${mode}-${tri.property}-${tri.order}`) {
            const donneesPromesse = recupereLesDonnees(mode, 20, 0, tri, valeurRecherche);

            if (donneesPromesse) {
                donneesPromesse.then(async (data) => {
                    const ripsRecuperes = await data.json();
                    if ('user' === mode) {
                        setRipsAAfficher(ripsRecuperes.rows);
                    }
                    if ('all' === mode) {
                        setRipsAAfficherAll(ripsRecuperes.rows);
                    }
                    if ('allForManager' === mode) {
                        setRipsAAfficherAllForManager(ripsRecuperes.rows);
                    }
                    if ('outdated' === mode) {
                        setRipsAAfficherOutdated(ripsRecuperes.rows);
                    }
                    if ('archives' === mode) {
                        setRipsAAfficherArchives(ripsRecuperes.rows);
                    }
                    setNombreTotalDeRips(ripsRecuperes.count);
                });
            }
        }
        setLastCall(`${valeurRecherche}-${mode}-${tri.property}-${tri.order}`);
    }, [valeurRecherche, mode, tri]);

    const removeItem = (id) => {
        if ('archives' === mode) {
            setRipsAAfficherArchives([
                ...ripsAAfficherArchives.filter((element) => element.id !== id),
            ]);
        }
        if ('all' === mode) {
            setRipsAAfficherAll([
                ...ripsAAfficherAll.filter((element) => element.id !== id),
            ]);
        }
        if ('allForManager' === mode) {
            setRipsAAfficherAllForManager([
                ...ripsAAfficherAllForManager.filter((element) => element.id !== id),
            ]);
        }
        if ('outdated' === mode) {
            setRipsAAfficherOutdated([
                ...ripsAAfficherOutdated.filter((element) => element.id !== id),
            ]);
        }
    };

    useEffect(() => {
        if ('archives' === mode || 'all' === mode || 'outdated' === mode || 'allForManager' === mode) {
            removeItem(ripAEnlever);
        }
        setRipAEnlever(null);
    }, [ripAEnlever]);

    return (
        <RipLayout className="rip-list" searchName="rip" setMotRecherche={setMotRecherche}>
            {
                false === enLigne && (
                    <div className="rip-list__message">
                        <Icon className="rip-list__message-icone" icon="info-circle" />
                        <p className="rip-list__message-texte">Vous êtes en mode déconnecté. Pour accéder aux autres sections, connectez-vous à internet.</p>
                    </div>)
            }
            {
                false === session.shoyo.shoyoUp && (
                    <div className="rip-list__shoyodown">
                        <Icon icon="warning" className="rip-list__shoyodown-icone" />
                        <p className="rip-list__shoyodown-texte">Les fonctionnalités eDoc sont momentanément indisponibles.</p>
                    </div>)
            }
            <Modal show={modalOuverte} onHide={() => setModalOuverte(false)} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{contenuModal.titre}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <ReactMarkdown>{contenuModal.texte}</ReactMarkdown>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="rip-list__modal__footer">
                        <Checkbox onClick={() => metAjourLecturePopUp()} >
                            J&apos;ai lu, ne plus afficher le message
                        </Checkbox>
                        <Button bsClass="rip-list__modal__footer-bouton" onClick={() => setModalOuverte(false)}>Fermer</Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <VersionWarning />
            <BrowserWarning />
            <ModeForm setMode={setMode} />
            {'user' === mode && <Body listeRips={ripsAAfficher} motRecherche={motRecherche} recuperationDonnees={recupereLesDonnees} mode={mode} tri={tri} setTri={setTri} nombreTotalDeRips={nombreTotalDeRips} setRipAEnlever={setRipAEnlever} />}
            {'all' === mode && <Body listeRips={ripsAAfficherAll} motRecherche={motRecherche} recuperationDonnees={recupereLesDonnees} mode={mode} tri={tri} setTri={setTri} nombreTotalDeRips={nombreTotalDeRips} setRipAEnlever={setRipAEnlever} />}
            {'allForManager' === mode && <Body listeRips={ripsAAfficherAllForManager} motRecherche={motRecherche} recuperationDonnees={recupereLesDonnees} mode={mode} tri={tri} setTri={setTri} nombreTotalDeRips={nombreTotalDeRips} setRipAEnlever={setRipAEnlever} />}
            {'outdated' === mode && <Body listeRips={ripsAAfficherOutdated} motRecherche={motRecherche} recuperationDonnees={recupereLesDonnees} mode={mode} tri={tri} setTri={setTri} nombreTotalDeRips={nombreTotalDeRips} setRipAEnlever={setRipAEnlever} />}
            {'archives' === mode && <Body listeRips={ripsAAfficherArchives} motRecherche={motRecherche} recuperationDonnees={recupereLesDonnees} mode={mode} tri={tri} setTri={setTri} nombreTotalDeRips={nombreTotalDeRips} session={session} setRipAEnlever={setRipAEnlever} />}
        </RipLayout>
    );
};

List.propTypes = {
    session: PropTypes.shape({
        id: PropTypes.string,
        entity: PropTypes.shape({
            id: PropTypes.string,
        }),
        managerCddIds: PropTypes.shape({}),
        status: PropTypes.string,
        shoyo: {
            shoyoUp: PropTypes.bool,
        },
    }).isRequired,
    valeurRecherche: PropTypes.string,
};

export default compose(
    withSession,
    connect((state) => ({
        valeurRecherche: get(['lists', 'rip', 'search'], state),
    })),
)(List);
