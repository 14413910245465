const activeCategory = {
    1000: 'Agriculteurs exploitants',
    2100: 'Artisans',
    2200: 'Commerçants et assimilés',
    2300: 'Chefs d\'entreprise de 10 salariés ou plus',
    3100: 'Professions libérales et assimilés',
    3200: 'Cadres de la fonction publique, professions intellectuelles et artistiques',
    3600: 'Cadres d\'entreprise',
    4100: 'Professions intermédiaires de l\'enseignement, de la santé, de la fonction publique et assimilés',
    4600: 'Professions intermédiaires administratives et commerciales des entreprises',
    4700: 'Techniciens',
    4800: 'Contremaîtres, agents de maîtrise',
    5100: 'Employés de la fonction publique',
    5400: 'Employés administratifs d\'entreprise',
    5500: 'Employés de commerce',
    5600: 'Personnels des services directs aux particuliers',
    6100: 'Ouvriers qualifiés',
    6600: 'Ouvriers non qualifiés',
    6900: 'Ouvriers agricoles',
};

export default {
    active: activeCategory,
    seekingJob: {
        8100: 'Demandeur d\'emploi',
        ...activeCategory,
    },
    student: {
        8400: 'Étudiant/Apprenti/Élève',
    },
    retired: {
        7100: 'Anciens agriculteurs exploitants',
        7200: 'Anciens artisans, commerçants, chefs d\'entreprise',
        7300: 'Anciens cadres et professions intermédiaires',
        7600: 'Anciens employés et ouvriers',
        7900: 'Retraités ancienne activité inconnue',
    },
    unemployed: {
        8200: 'Sans activité salariale',
    },
    TNS: activeCategory,
};
