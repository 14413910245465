import { set, get, flowRight } from 'lodash/fp';
import { withProps } from 'recompose';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { countErrors } from 'common/formValidation/lib';
import baseDataForm from 'components/dataForm';
import ripValidation from 'common/formValidation/rip';
import { getRequiredFieldActivationDate } from 'actions';
import scrollToFirstError from '../form/scrollToFirstError';
import multiStepValidation from '../multiStepValidation';
import { withSession } from '../sessions';

const dataForm = ({ form, renamedFields = {}, ...options }) => flowRight([
    // setup multi step validation
    multiStepValidation(ripValidation),
    // override the validate props
    connect(null, { change }),
    connect(({ requiredFieldActivationDateReducer }) => ({
        requiredFieldActivationDateReducer,
    }), { getRequiredFieldActivationDate }),

    withSession,
    withProps(({ validate, change: updateField }) => ({
        // inform the form name
        form,
        // override the validate method
        validate: (values, props) => {
            if (props.form !== 'rip-appointment') {
                props.getRequiredFieldActivationDate('investorProfiles');
                props.getRequiredFieldActivationDate('lifePlans');
                props.getRequiredFieldActivationDate('partner.lifePlans');
                props.getRequiredFieldActivationDate('employmentStatus');
                props.getRequiredFieldActivationDate('socioProfessionalCategory');
                props.getRequiredFieldActivationDate('partnerEmploymentStatus');
                props.getRequiredFieldActivationDate('partnerSocioProfessionalCategory');
            }
            // validate the form
            const errors = validate(values, props);

            // count the errors
            // the appointment is not included in the rip validation
            const errorCount = countErrors(errors, ['rip-commitment']);

            if (values.errorCount !== errorCount) {
                // update the form
                updateField(form, 'errorCount', errorCount);
            }

            // return only the errors for the current step
            return Object.entries(renamedFields).reduce(
                (formErrors, [sourceField, relatedField]) =>
                    set(relatedField, get(sourceField, formErrors), formErrors),
                get(form, errors),
            );
        },
    })),
    // initialize the original data form
    baseDataForm({
        // smooth scroll on errors
        onSubmitFail: scrollToFirstError({ containerId: 'main-form' }),
        // override with the given options
        ...options,
    }),
]);

export default dataForm;
