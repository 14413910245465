const modes = {
    isInactiveWithRips: {
        label: 'Conseillers inactifs avec RIP',
        icon: 'users',
    },
    itemsActiveRips: {
        label: 'Conseillers actifs',
        icon: 'users',
    },
    isInactiveWithoutRipsActive: {
        label: 'Conseillers inactifs sans RIP actif',
        icon: 'users',
    },
};

export default modes;
