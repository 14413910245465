import PropTypes from 'prop-types';
import React from 'react';
import { Legend, Pie, PieChart } from 'recharts';
import { formatPercentage } from '../../../utils/format/index';

const formatLabel = ({ value }) => formatPercentage(value);

const ProfileChart = ({
    legendWrapperStyle,
    colors: { euro: euroColor, stock: stockColor },
    chartSize,
    profileData,
}) => {
    const chartData = [
        { value: profileData.data.euro, name: 'fonds en €', fill: euroColor },
        { value: profileData.data.uc, name: 'Unités de Compte', fill: stockColor },
    ];

    return (
        <PieChart height={chartSize} width={chartSize}>
            <Pie
                data={chartData}
                dataKey="value"
                nameKey="name"
                isAnimationActive={false}
                label={formatLabel}
                labelLine
                legendType="circle"
            />
            <Legend
                layout="vertical"
                verticalAlign="bottom"
                wrapperStyle={legendWrapperStyle}
            />
        </PieChart>
    );
};

ProfileChart.propTypes = {
    colors: PropTypes.shape({
        euro: PropTypes.string.isRequired,
        stock: PropTypes.string.isRequired,
    }),
    profileData: PropTypes.shape({
        data: PropTypes.shape({
            euro: PropTypes.number,
            uc: PropTypes.number,
        }),
    }).isRequired,
    legendWrapperStyle: PropTypes.shape({}),
    chartSize: PropTypes.number,
};

ProfileChart.defaultProps = {
    colors: { euro: '#a2ebac', stock: '#248fdf' },
    chartSize: 420,
    legendWrapperStyle: null,
};

export default ProfileChart;
