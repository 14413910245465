import { get } from 'lodash/fp';
import { useSession } from '../components/sessions';

const isWhiteLabelSga = (entityName) => {
    const session = useSession();

    // on vérifie la SGA de la marque blanche
    return session.instanceInovia && entityName === (get('settings.displayName', session)).toLowerCase();
};

export default isWhiteLabelSga;
