import React from 'react';
import { mapProps } from 'recompose';
import Layout from '../Layout';
import TitleField from './TitleField';

const TitleLayout = mapProps(({ title, ...props }) => ({
    ...props,
    header: <TitleField title={title} />,
}))(Layout);

export default TitleLayout;
