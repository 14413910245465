import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Item = ({ title, value, help, important, bold, negative, allowNull }) => (
    <div
        className={
            classNames(
                'summary-item',
                { important },
                { bold },
                { negative },
            )
        }
    >
        <div className={classNames('summary-item-inner', { 'no-title': !title })}>
            {title ? (<dt>{title}{help ? <span className="summary-help">{help}</span> : null}</dt>) : null}
            <dd>{(allowNull) ? null : (value || 'NC')}</dd>
        </div>
    </div>
);

Item.propTypes = {
    allowNull: PropTypes.bool,
    negative: PropTypes.bool,
    bold: PropTypes.bool,
    important: PropTypes.bool,
    title: PropTypes.node,
    value: PropTypes.node,
    help: PropTypes.string,
};

Item.defaultProps = {
    bold: false,
    important: false,
    help: null,
    value: null,
};

export default Item;
