// Patrimoine immobilier

import { get, sum } from 'lodash/fp';

const safeSum = (property, collection) => collection.reduce((suma, item) => suma + (item[property] || 0), 0);

const types = {
    secondHome: 'Résidence secondaire',
    rentalProperty: 'Locatifs',
    other: 'Autres',
};

const computeProperties = (rip) => {
    const properties = [];
    let totalMensualites = [];
    if ('owner' === rip.mainResidence) {
        if (rip.mainResidenceMortgages && get('mainResidenceMortgages', rip).length > 0) {
            rip.mainResidenceMortgages.forEach((credit) => totalMensualites.push(parseInt(credit.dueDateAmount, 10)));
        }

        properties.push({
            title: 'Résidence principale',
            owner: rip.mainResidenceTenant,
            acquisitionValue: rip.mainResidenceAcquisitionPrice,
            currentValue: parseInt(rip.mainResidenceEstimatedValue, 10),
            acquisitionYear: rip.mainResidenceAcquisitionYear,
            loanEnd: 'owed' === rip.mainResidenceMortgage ? rip.mainResidenceEndLoan : rip.mainResidenceLastDueDate,
        });
    }

    if (rip.property && get('property', rip).length > 0) {
        rip.property.forEach((propriete) => {
            if (propriete.mortgages && get('mortgages', propriete).length > 0) {
                propriete.mortgages.forEach((credit) => totalMensualites.push(parseInt(credit.dueDateAmount, 10)));
            }
        });
        const extractionDateFindeCredit = (proper) => proper.map((x) => ('owed' === x.type ? x.endLoan : x.lastDueDate));

        properties.push(...rip.property.map((property) => ({
            id: property.id,
            title: types[property.type],
            owner: property.owner,
            acquisitionValue: property.acquisitionPrice,
            currentValue: parseInt(property.estimatedValue, 10),
            acquisitionYear: property.acquisitionYear,
            loanEnd: property.mortgages && extractionDateFindeCredit(property.mortgages)[0],
        })));
    }

    totalMensualites = parseInt(sum(totalMensualites), 10);

    if (!properties.length) {
        return null;
    }

    return { realEstate: { valeurTotale: safeSum('currentValue', properties), totalMensualites, properties } };
};

export default computeProperties;
