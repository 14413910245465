import { get } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { FaIcon } from '../../../common/components/fa';
import { hideMenu, showOverlay } from '../../../actions';
import hasSigned, { hasSignedClient, hasSignedClientAndPartner, hasSignedPartner, separationRegime } from '../../../utils/hasSigned';
import MenuButton from '../../menu/Button';
import { withSession } from '../../sessions';
import withRouter from '../../withRouter';

import './Items.css';
import { isCouple } from '../../../../../common/src/utils/hasPartner';

const showRecommendationButton = (rip, session) => {
    if (session.instanceTheseis) {
        if (separationRegime(rip) || !isCouple(rip.familySituation)) {
            return hasSignedClient(rip) || hasSignedPartner(rip);
        }

        return hasSignedClientAndPartner(rip);
    }

    return hasSigned(rip);
};

const renderPrecoButton = (rip, onClickItem, session) => {
    if (!session.needApprovalOnCIFDocument && !session.needApprovalOnSimulations) {
        return null;
    }

    if (showRecommendationButton(rip, session)) {
        return (
            <div className="item" >
                <span>Préconisations</span>
                <MenuButton onClick={onClickItem('recommendation')}>
                    <FaIcon icon="share" />
                </MenuButton>
            </div>
        );
    }

    return (
        <OverlayTrigger
            trigger={['hover', 'click']}
            overlay={<Popover className="form-field-info-popover" placement="bottom" >Pour accéder aux préconisations, vous devez signer le RIP.</Popover>}
            placement="left"
            rootClose
        >
            <div className="item">
                <div className="disabled" >
                    <span>Préconisations</span>
                    <MenuButton onClick={onClickItem('recommendation')} disabled color="disabled">
                        <FaIcon icon="share" />
                    </MenuButton>
                </div>
            </div>
        </OverlayTrigger>
    );
};

const MenuItems = ({ onClickItem, rip, session }) => (
    <div className="rip-menu-items">
        {session.isAdmin || ((session.managedIds.includes(rip.user.entity.id) || 'caseManager' === session.status) && !session.isAdminReseau) ? (
            <div className="item">
                <span>Réattribuer</span>
                <MenuButton onClick={onClickItem('reattribute')}>
                    <FaIcon icon="exchange" />
                </MenuButton>
            </div>
        ) : null}
        {'IFB' === get('settings.displayName', session) ? (
            <div className="item">
                <span>Guide de vente</span>
                <MenuButton onClick={onClickItem('ifb-sells-guide')}>
                    <FaIcon icon="file" />
                </MenuButton>
            </div>
        ) : null}

        {renderPrecoButton(rip, onClickItem, session)}

        <div className="item">
            <span>Parrainage</span>
            <MenuButton onClick={onClickItem('sponsoring')}>
                <FaIcon icon="id-card-o" />
            </MenuButton>
        </div>
        <div className="item">
            <span>Récapitulatif</span>
            <MenuButton onClick={onClickItem('summary')}>
                <FaIcon icon="pie-chart" />
            </MenuButton>
        </div>
        <div className="item">
            <span>Simulateurs</span>
            <MenuButton onClick={onClickItem('simulators')}>
                <FaIcon icon="bar-chart" />
            </MenuButton>
        </div>
        <div className="item">
            <span>Notes</span>
            <MenuButton onClick={onClickItem('comments')}>
                <FaIcon icon="commenting" />
            </MenuButton>
        </div>
    </div>
);

MenuItems.propTypes = {
    onClickItem: PropTypes.func.isRequired,
    rip: PropTypes.shape({
        user: PropTypes.shape({
            entity: PropTypes.shape({
                id: PropTypes.string,
            }),
        }),
    }).isRequired,
    session: PropTypes.shape({
        isAdmin: PropTypes.bool,
        isAdminReseau: PropTypes.bool,
        managedIds: PropTypes.string,
        entity: PropTypes.shape({
            name: PropTypes.string,
        }),
        status: PropTypes.string,
        needApprovalOnSimulations: PropTypes.bool,
        needApprovalOnCIFDocument: PropTypes.bool,
    }).isRequired,
};

const mapStateToProps = (state, { params: { id } }) => ({
    rip: state.data.rip[id],
});

const mapDispatchToProps = (dispatch) => ({
    onClickItem: (overlay) => () => {
        dispatch(hideMenu());
        dispatch(showOverlay(overlay));
    },
});

export default compose(
    withRouter,
    withSession,
    connect(mapStateToProps, mapDispatchToProps),
)(MenuItems);
