import PropTypes from 'prop-types';
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';

import './TitleField.scss';

class TitleField extends Component {
    constructor(props) {
        super(props);
        this.inputRef = createRef();
    }

    resetSearch() {
        this.props.onChange('');
        this.inputRef.current.value = '';
    }

    render() {
        const { title } = this.props;

        return (
            <div className="layout-title-field">
                <h1>{title}</h1>
            </div>
        );
    }
}

TitleField.propTypes = {
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string,
};

TitleField.defaultProps = {
    title: null,
};

export default connect()(TitleField);
