import { has } from 'lodash/fp';
import PropTypes from 'prop-types';
import React from 'react';
import { initialFundingReasonTypes } from 'common/choices';
import FormGroup from 'components/form/Group';
import Title from 'components/Title';
import { GridLayout } from 'components/form/layout';
import { ChoiceField, DateField, IntegerField, PercentageField, SelectField } from 'components/form/field';
import GridRadioButton from 'components/form/GridRadioButton';
import GridRadioGroup from 'components/form/GridRadioGroup';
import { formValues } from 'redux-form';
import useSettings, { useSettingsMemo } from 'components/sessions/useSettings';
import sortedAlphabetically from 'utils/sortedAlphabetically';
import { useSession } from '../../../../sessions';
import ConnectedProductChart from './ConnectedProductChart';

const getContractsTypes = (lifeInsuranceTypes, investorProfile, permissions, products) => Object
    .entries(lifeInsuranceTypes)
    .reduce((types, [type, { deleted, name, suffix, onlyCA }]) => {
        if (!deleted && has([type], products) && (!onlyCA || permissions.isCaseManager())) {
            return { ...types, [type]: suffix ? `${name} ${suffix}` : name };
        }

        return types;
    }, {});

const Settings = ({ investorProfile, contractType }) => {
    const { permissions } = useSession();

    const products = useSettings(['simulation', 'lifeInsurance', 'types']);

    const profilOuLaRepartitionDuProduitNEstPasVide = (product) => Object.entries(product).filter((x) => undefined !== x[1].repartition && null !== x[1].repartition && 0 !== x[1].repartition.length);

    let filtrageProduits = Object.entries(products).filter((product) => {
        const profileActif = profilOuLaRepartitionDuProduitNEstPasVide(product[1].profiles).flat();

        return profileActif.find((profil) => profil === investorProfile);
    });

    filtrageProduits = Object.fromEntries(filtrageProduits);

    const contractsTypes = useSettingsMemo(({ simulation: { lifeInsurance } }) => getContractsTypes(
        lifeInsurance.types,
        investorProfile,
        permissions,
        filtrageProduits,
    ), [investorProfile, permissions]);

    return (
        <FormGroup>
            <Title><b>Caractéristiques et paramètres</b></Title>
            <GridLayout className="condensed">
                <div className="double">
                    <ChoiceField
                        name="data.contractType"
                        title="Type de contrat"
                        choices={sortedAlphabetically(contractsTypes)}
                        required
                    />
                    {/* Kept for later re-use */}
                    {false && contractType && !!contractType.match(/Immo$/i) && (
                        <div className="alert alert-warning">
                            Attention, cette allocation est soumise à dérogation
                        </div>
                    )}
                </div>
                <div className="container-chart">
                    <div className="product-chart">
                        <ConnectedProductChart
                            className="in-box"
                            profile={investorProfile}
                            paymentType="initialPayment"
                        />
                        <ConnectedProductChart
                            className="in-box"
                            profile={investorProfile}
                            paymentType="scheduledPayment"
                        />
                    </div>
                </div>
                <DateField
                    name="data.subscriptionDate"
                    title="Date de souscription"
                    required
                />
                <IntegerField
                    name="data.duration"
                    title="Durée en années"
                    required
                />
                <IntegerField
                    name="data.initialFunding"
                    title="Montant initial"
                    required
                />
                <PercentageField
                    name="data.entryFees"
                    title="Frais d'entrée pour le montant initial (%)"
                    required
                />
                <SelectField
                    name="data.initialFundingReason"
                    title="Origine du versement"
                    required
                >
                    <option />
                    {Object.entries(initialFundingReasonTypes).map(([name, title]) => (
                        <option key={name} value={name}>{title}</option>
                    ))}
                </SelectField>
                <FormGroup className="form-group-grid">
                    <h6>Fiscalit&eacute; des placements</h6>
                    <GridRadioGroup name="data.taxMode" className="as-item">
                        <GridRadioButton label="PFU" value="pfu" />
                        <GridRadioButton label="IR" value="ir" />
                    </GridRadioGroup>
                </FormGroup>
            </GridLayout>
        </FormGroup>
    );
};

Settings.propTypes = {
    investorProfile: PropTypes.string.isRequired,
    contractType: PropTypes.string.isRequired,
};

export default formValues({ investorProfile: 'data.profile', contractType: 'data.contractType' })(Settings);
